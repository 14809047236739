<template>
  <div>
    <v-row>
      <v-col
        class="d-none d-md-flex align-center justify-center"
        cols="12"
        lg="7"
        xl="6"
        style="height: 102vh; background-color: #691e32"
      >
        <v-container>
          <div>
            <v-row justify="center">
              <v-col cols="8" xl="5">
                <div>
                  <img
                      src="@/assets/images/logo_blanco.png"
                      style="max-height: 100px"
                  />

                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
      <v-col class="d-flex align-center" cols="12" lg="5" xl="6">
        <v-container>
          <div class="pa-7 pa-sm-12">
            <v-row>
              <v-col cols="12" lg="9" xl="6">
                <h2
                  class="font-weight-bold mt-4 blue-grey--text text--darken-2"
                >
                  Autenticaci&oacute;n
                </h2>
                <h6 v-show="false" class="subtitle-1">
                  ¿No tiene una cuenta a&uacute;n?
                  <a class href="/under-construction">Regístrese</a>
                </h6>

                <v-form
                  ref="form"
                  v-model="valid"
                  lazy-validation
                >
                  <v-text-field
                    v-model="email"
                    :rules="emailRules"
                    class="mt-4"
                    label="Correo"
                    outlined
                    required
                    :disabled="progressDialog"
                    @keypress.enter="submit"
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :rules="passwordRules"
                    :disabled="progressDialog"
                    :type="showPassword ? 'text' : 'password'"
                    label="Contraseña"
                    outlined
                    required
                    @keypress.enter="submit"
                    @click:append="showPassword = !showPassword"
                  ></v-text-field>

                  <!--<div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                                        <v-checkbox
                                                v-model="checkbox"
                                                label="Recordarme?"
                                                required
                                        ></v-checkbox>
                                        <div class="ml-auto">
                                            <a href="javascript:void(0)" class="link">Olvidó la contraseña?</a>
                                        </div>
                                    </div>-->

                  <v-btn
                    :disabled="!valid"
                    block
                    class="mr-4"
                    style="background-color: #691e32; color: white"
                    submit
                    :loading="progressDialog"
                    @click="submit"
                  >
                    Entrar
                  </v-btn>
                </v-form>
                <div class="text-center mt-6">
                  <!--mdi-home-circle-->
                  <v-chip v-show="false" class="mr-2" pill @click="goHome">
                    <v-avatar left>
                      <v-btn class="white--text" color="primary lighten-1">
                        <v-icon>mdi-home</v-icon>
                      </v-btn>
                    </v-avatar>
                    Ir al Inicio
                  </v-chip>
                  <v-chip class="mr-2" pill v-show="false">
                    <v-avatar left>
                      <v-btn class="white--text" color="primary lighten-1">
                        <v-icon>mdi-twitter</v-icon>
                      </v-btn>
                    </v-avatar>
                    Twitter
                  </v-chip>
                  <v-chip pill v-show="false">
                    <v-avatar left>
                      <v-btn class="white--text" color="primary lighten-1">
                        <v-icon>mdi-facebook</v-icon>
                      </v-btn>
                    </v-avatar>
                    Facebook
                  </v-chip>
                  <div style="height: 70px"></div>
                </div>
              </v-col>
            </v-row>
          </div>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="dialogError" 
      color="red accent-2"
      :timeout="2000" 
      rounded="pill"
      absolute
      elevation="24"
      shaped>
      {{ messageError }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" icon @click="dialogError = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import loginService from "@/providers/LoginService";

export default {
  name: "FormLoginComponent",
  data: () => ({
    valid: true,
    showPassword: false,
    progressDialog: false,
    dialogError: false,
    messageError: "",
    email: "",
    checkbox: false,
    emailRules: [
      (v) => !!v || "El Correo Electrónico es obligatorio",
      (v) => /.+@.+\..+/.test(v) || "El Correo Electrónico debe ser válido",
    ],
    password: "",
    passwordRules: [
      (v) => !!v || "La contraseña es obligatoria",
      (v) =>
        (v && v.length >= 8) ||
        "La contraseña debe tener al menos de 8 caracteres",
    ],
  }),
  watch: {
    dialogError(val) {
      val || this.closeDialogError();
    },
  },
  methods: {
    goHome() {
      this.$store.dispatch("clearState");
      this.$router.push({ path: "/homepage" });
    },
    submit() {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.progressDialog = true;
        this.doLogin();
      }
    },
    doLogin() {
      let passport = {
        email: this.email,
        password: this.password,
      };
      loginService.loginUser(passport).then((response) => {
        this.dialogError = false;
        if (response.valid) {
          this.closeDialogError();
          sessionStorage.setItem('token', response.token);
          this.$router.push({name: "TwoFactorView"});
        } else {
          this.dialogError = true;
          this.messageError = response.messageText;
        }
      }).catch((err)=>{
        this.messageError = err.msg;
        this.dialogError = true;
      }).finally(()=>{
        this.progressDialog = false;
      });
    },
    closeDialogError() {
      this.dialogError = false;
      this.messageError = "";
    },
  },
};
</script>
